import styles from './Header.module.css';
import NavBar from '../Navbar/NavBar';
let Header = ()=>{
    return(
        <>
        <nav>
        <NavBar/>
        </nav>
        </>
    )
}
export default Header