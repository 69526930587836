import styles from './Home.module.css';

let Home = ()=>{
return(
    <>
    <div className={styles.BackgroundImage}>
        <h1>Ticket Booking System</h1>
    </div>
    </>
)
}
export default Home;